// src/App.js
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Component/Header';
import ImageCarousel from './Component/ImageCarousel';
import Footer from './Component/Footer';
import Categories from './Component/Categories';
import DetailsPage from './Component/DetailsPage';
import ContactBar from './Component/contactbar';
import About from './Component/About';
import Gallery from './Component/Gallery';
import Catalogue from './Component/Catalogue';
import TeamStarryNights from './Component/TeamStarryNights';
import Honeymoon from './Component/Categories Details/Honeymoon';
import GroupTours from './Component/Categories Details/GroupTours';
import Camping from './Component/Categories Details/Camping';
import CampingWithTrekking from './Component/Categories Details/CampingWithTrekking';
import FixedDepartures from './Component/Categories Details/FixedDepartures';
import BhaktiYatra from './Component/Categories Details/BhaktiYatra';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ContactBar />
                <ImageCarousel />
                <Categories />
                <Catalogue />
                <About />
                <TeamStarryNights />
                <Footer />
              </>
            }
          />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/About" element={<About />} />
          <Route path="/contacts" element={<Footer />} />
          <Route path="/Catalogue" element={<Catalogue />} />
          <Route path="/details/:imageName" element={<DetailsPage />} />
          <Route path="/category/Honeymoon" element={<Honeymoon />} />
          <Route path="/category/Group Tours" element={<GroupTours />} />
          <Route path="/category/Camping" element={<Camping />} />
          <Route path="/category/Camping with Trekking" element={<CampingWithTrekking />} />
          <Route path="/category/Fixed Departures" element={<FixedDepartures />} />
          <Route path="/category/Bhakti Yatra" element={<BhaktiYatra />} />
         
         
        </Routes>
      </div>
    </Router>
  );
}

export default App;
