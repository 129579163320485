import React from 'react';
import './About.css';
import missionImage from '../Other Images/our mission.jpg'; // Add the correct path to your images


const About = () => {
  return (
    <div className="about-container">
      <h1 className="about-us-heading">About Us</h1>
      <section className="vision-section">
        <img src={missionImage} alt="Our Mission" className="section-image" />
        <h2 className="vision-heading">Our Vision</h2>
        <div className="text-content">
          <p>
            In December 2020, We started STARRY NIGHTS with a simple belief that Everyone Must Travel. The vision was to make trekking accessible to all by providing comprehensive information and promoting the true spirit of trekking in India.
          </p>
          <p>
            At STARRY NIGHTS, we are committed to delivering the highest quality of service while ensuring that travel remains affordable for everyone. Our mission is to offer exceptional travel experiences that make every journey easy, enjoyable, and fulfilling.
          </p>
          <p>
            We believe that unforgettable adventures shouldn’t come with a hefty price tag. Our goal is to spark feelings of love, joy, and excitement in every traveler, giving you the confidence to pursue your dreams. With us, you’ll explore the world’s wonders, creating cherished memories that last a lifetime.
          </p>
        </div>
      </section>

      <section className="known-for-section">
        
        <h2 className="known-for-heading">What We Are Known For</h2>
        <div className="text-content">
          <p>
            Our team provides expert advice on the most spectacular sights every tourist should visit and the best places. Pick your vacation from the array of package options we offer, or customize them to suit your needs and budget. We offer packages for every kind of vacation you plan, whether it’s a family trip, pilgrimage, cultural tour, or adventurous journey.
          </p>
          <p>
            Our team has profound experience in planning and executing perfect holiday plans for our clients. We adhere to all government regulations and follow strict safety procedures to provide our clients with high-quality services. We never compromise on quality, ensuring our guests have a wonderful and satisfying Himalayan trip.
          </p>
          <p>
            Client satisfaction is our motto, and we have consistently met or exceeded expectations. All these qualities make our organization one of the most sought-after travel planners in the country.
          </p>
        </div>
      </section>

      <section className="tourist-love-section">
        
        <h2 className="tourist-love-heading">Why Travellers Love StarryNights</h2>
        <h3 className="tourist-love-subheading">"Travellers have always respected StarryNights, whether or not they trek with us."</h3>
        <div className="text-content">
          <p>
            Travellers love StarryNights because we focus on providing meaningful, personalized travel experiences. We go the extra mile to ensure that every traveler feels valued and cared for, no matter their destination or adventure.
          </p>
          <p>
            We stand out by offering tailored itineraries that are designed to meet the unique preferences and needs of our clients. Our attention to detail, safety-first approach, and dedication to customer satisfaction ensure that every trip with StarryNights is smooth, enjoyable, and memorable.
          </p>
          <p>
            Even tourists who don’t trek with us appreciate our transparency, reliability, and the genuine care we extend to every traveler. With StarryNights, tourists can expect comfort, thrill, and an unforgettable journey filled with exploration and joy.
          </p>
        </div>
      </section>

      {/* Contact Us Section */}
     
    </div>
  );
};

export default About;
