import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import StaryNight from '../STARRY-NIGHTS-Header.jpg';
import { FaHome, FaInfoCircle, FaImages, FaPhoneAlt, FaWhatsapp} from 'react-icons/fa';
import { FaArrowTrendUp } from 'react-icons/fa6';


const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle mobile menu and prevent page scrolling
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  };

  // Close menu on resize to prevent stuck states
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMenuOpen(false);
        document.body.classList.remove('menu-open');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div>
        <img
          src={StaryNight}
          alt="Stary Nights Header"
          className="w-full h-auto object-cover"
        />
      </div>

      <header className="header">
        <div className="header-content">
          {/* Desktop Navigation */}
          <nav className="desktop-nav">
            <ul className="flex space-x-8">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/About">About</Link></li>
              <li><Link to="/gallery">Gallery</Link></li>
              <li><Link to="/contacts">Contact</Link></li>
            </ul>
          </nav>

        
        </div>

        {/* Social Media Links in a Separate Row, Aligned Right */}
        <div className="social-media">
        <a href="tel:+918847755042" className="instagram-icon">
            <FaPhoneAlt size={20} />
          </a>
          <a
            href="https://wa.me/message/UTCOF3APCTTKP1 "
            className="whatsapp-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={20} />
          </a>
          
        </div>
        <nav className="mobile-bottom-nav">
          <ul className="flex justify-around items-center">
            <li><Link to="/"><FaHome size={30} /></Link></li>
            <li><Link to="/About"><FaInfoCircle size={30} /></Link></li>
            <li><Link to="/gallery"><FaImages size={30} /></Link></li>
            <li><Link to="/Catalogue "><FaArrowTrendUp size={30} /></Link></li>
            <li><Link to="https://wa.me/message/UTCOF3APCTTKP1"target="_blank" rel="noopener noreferrer" ><FaWhatsapp size={30} /></Link></li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Header;
