import React, { useEffect, useState } from 'react';
import './TeamStarryNights.css';

const importAll = (r) => {
  const imagesByFolder = {};

  r.keys().forEach((path) => {
    const parts = path.split('/');
    const folderPath = parts.slice(1, parts.length - 1).join('/');
    const folderName = folderPath || 'root';

    if (!imagesByFolder[folderName]) {
      imagesByFolder[folderName] = [];
    }

    imagesByFolder[folderName].push({
      src: r(path),
      name: path.split('/').pop(), // Extract filename
    });
  });

  // Sort images by name within each folder
  for (const folder in imagesByFolder) {
    imagesByFolder[folder].sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );
  }

  return imagesByFolder;
};

const TeamStarryNights = () => {
  const [images, setImages] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoom, setZoom] = useState(1); // Initial zoom level

  useEffect(() => {
    const galleryImages = importAll(require.context('../TeamStarryNights', true, /\.(jpg|jpeg|png)$/));
    setImages(galleryImages);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
    setZoom(1); // Reset zoom when opening a new image
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setZoom(1); // Reset zoom when closing the modal
  };

  const handleZoom = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.deltaY < 0) {
      setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level: 3
    } else {
      setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Min zoom level: 1
    }
  };

  const renderImages = (folder, images) => (
    <div className="team-section" key={folder}>
      <div className="team-grid">
        {images.map((image, index) => (
          <div
            className="team-item"
            key={index}
            onClick={() => openModal(image.src)}
          >
            <img src={image.src} alt={image.name} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="team-container">
      <h2 className="team-title">Team Starry Nights</h2>

      {Object.keys(images).length === 0 ? (
        <p>No images found.</p>
      ) : (
        Object.entries(images).map(([folder, images]) =>
          renderImages(folder, images)
        )
      )}

      {isModalOpen && (
        <div className="modal" onWheel={handleZoom}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img
              src={selectedImage}
              alt="Selected"
              className="modal-image"
              style={{ transform: `scale(${zoom})` }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamStarryNights;
