import React, { useEffect, useState } from 'react';
import './ImageCarousel.css';

const importAll = (r) => {
    return r.keys().map(r);
};

const images = importAll(require.context('../images', false, /\.jpg$/));

const ImageCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(0); // 1 for next, -1 for previous

    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 3000);

        return () => clearInterval(interval);
    }, [currentIndex]); // Add currentIndex to the dependency array

   

    const handleNext = () => {
        setDirection(1);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    return (
        <div className="carousel-container relative">
            <div className="carousel-wrapper">
                <img
                    src={images[(currentIndex - 1 + images.length) % images.length]} // Previous image
                    alt={`Slide ${currentIndex - 1}`}
                    className={`carousel-image prev-image ${direction === -1 ? 'slide-in-right' : 'slide-out-left'}`}
                />
                <img
                    src={images[currentIndex]}
                    alt={`Slide ${currentIndex + 1}`}
                    className={`carousel-image current-image ${direction === 1 ? 'slide-in-left' : 'slide-out-right'}`}
                />
                <img
                    src={images[(currentIndex + 1) % images.length]} // Next image
                    alt={`Slide ${currentIndex + 1}`}
                    className={`carousel-image next-image ${direction === 1 ? 'slide-out-left' : 'slide-in-right'}`}
                />
            </div>
           
        </div>
    );
};

export default ImageCarousel;
