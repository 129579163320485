import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './DetailsPage.css';
import html2pdf from 'html2pdf.js';

const DetailsPage = () => {
  const { imageName } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/CategoriesDocs/${imageName}.txt`);

        if (!response.ok || !response.headers.get('Content-Type').includes('text/plain')) {
          console.error('File not found or not a text file:', `${imageName}.txt`);
          setContent('Please contact us for more details.');
          return;
        }

        const textContent = await response.text();
        setContent(textContent);
      } catch (error) {
        console.error('Error fetching file:', error);
        setContent('Please contact us for more details.');
      }
    };

    fetchContent();
  }, [imageName]);

  const parseTextWithFormatting = (text) => {
    const regex = /(\*_.*?_\*|\*[^*]+\*|_[^_]+_|~[^~]+~|`[^`]+`)/g;

    return text.split(regex).map((part, index) => {
      if (part.startsWith('*_') && part.endsWith('_*')) {
        return <strong key={index}><em>{part.slice(2, -2)}</em></strong>;
      } else if (part.startsWith('*') && part.endsWith('*')) {
        return <strong key={index}>{part.slice(1, -1)}</strong>;
      } else if (part.startsWith('_') && part.endsWith('_')) {
        return <em key={index}>{part.slice(1, -1)}</em>;
      } else if (part.startsWith('~') && part.endsWith('~')) {
        return <del key={index}>{part.slice(1, -1)}</del>;
      } else if (part.startsWith('`') && part.endsWith('`')) {
        return <code key={index}>{part.slice(1, -1)}</code>;
      }
      return part;
    });
  };

  const handleDownloadPDF = () => {
    const element = document.createElement("div");

    // Set up styles to make the image a background
    element.style.position = "relative";
    element.style.width = "100%";
    element.style.maxWidth = "600px";
    element.style.padding = "20px";
    element.style.backgroundImage = 'F:/my-app/src/Other Images/letter head.jpg';
    element.style.backgroundSize = "cover";
    element.style.backgroundPosition = "center";
    element.style.color = "black"; // Ensure text is visible over the background
    element.style.padding = "40px";

    // Clone the content to be added on top of the background
    const contentElement = document.getElementById("details-content").cloneNode(true);
    contentElement.style.position = "relative";
    contentElement.style.zIndex = "2"; // Bring text to the foreground
    element.appendChild(contentElement);

    html2pdf()
      .set({
        filename: `${imageName}.pdf`,
        jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
        html2canvas: { scale: 2, logging: true, scrollY: 0 },
      })
      .from(element)
      .save();
  };

  const paragraphs = content
    .split(/\r?\n|\r|\n{2,}/)
    .filter((para) => para.trim() !== '');

  return (
    <div className="details-page-container">
      <h1><strong>Details</strong></h1>
      <div className="details-content" id="details-content">
        {paragraphs.length > 0 ? (
          paragraphs.map((paragraph, index) => (
            <p key={index}>{parseTextWithFormatting(paragraph)}</p>
          ))
        ) : (
          <p className="coming-soon">Please contact us for more details.</p>
        )}
      </div>
      <button className="back-button" onClick={() => navigate(-1)}>
        <i className="fas fa-arrow-left"></i> Back
      </button>
      <button className="download-button" onClick={handleDownloadPDF}>
        <i className="fas fa-download"></i> Download Details
      </button>
    </div>
  );
};

export default DetailsPage;
