import React from 'react';
import './contactbar.css';

const ContactBar = () => {
  return (
    <div className="contact-bar">
      <div className="contact-info">
        {/* Single block of content for seamless scrolling */}
     
        <span> Sahyadri | Kokan | Forts | Ladakh | Himachal | Assam | Rajasthan | Kerala | Chennai </span>
        <span> Trekking | Camping | Adventure tours | Educational trips | Corporate tours | Holidays planning </span>
      </div>
    </div>
  );
};

export default ContactBar;
