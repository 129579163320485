import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Catalogue.css';

// Import images and map them to get src and name without extension
const importAllImages = (r) =>
  r.keys().map((key) => ({
    src: r(key),
    name: key.split('/').pop().split('.')[0], // Extract name before first dot
  }));

const images = importAllImages(require.context('../catlogueimages', false, /\.jpg$/));

const Catalogue = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Slideshow effect: Change current image every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  // Handle image click and load corresponding .txt file
  const handleImageClick = (imageName) => {
    const txtFilePath = `/CategoriesDocs/${imageName}.txt`;

    setLoading(true);
    fetch(txtFilePath)
      .then((response) => {
        if (!response.ok) throw new Error('Text file not found');
        return response.text();
      })
      .then((textContent) => {
        setLoading(false);
        navigate(`/details/${imageName}`, { state: { content: textContent } });
      })
      .catch((error) => {
        console.error('Error loading .txt file:', error);
        setLoading(false);
        navigate(`/details/${imageName}`, {
          state: { content: 'Details not available.' },
        });
      });
  };

  // Function to get the current, previous, and next images for thumbnails
  const getThumbnails = () => {
    const totalImages = images.length;
    return [
      images[(currentIndex - 1 + totalImages) % totalImages], // Previous image
      images[currentIndex], // Current image
      images[(currentIndex + 1) % totalImages], // Next image
    ];
  };

  // Generate dot indicators based on the number of images
  const getDots = () => {
    return images.map((_, index) => index);
  };

  return (
    <div className="catalogue-container">
     <h1 className="about-us-heading">Trending </h1>
      {loading && <div className="loading">LOADING...</div>}

      {images.length === 0 ? (
        <div className="coming-soon">Coming Soon</div>
      ) : (
        <>
          <div className="slideshow-container">
            <div
              className="slideshow-item"
              onClick={() => handleImageClick(images[currentIndex].name)}
            >
              <img
                src={images[currentIndex].src}
                alt={`Slide ${currentIndex + 1}`}
                className="slideshow-image"
              />
              <h3 className="catalogue-title">{images[currentIndex].name}</h3>
            </div>
          </div>

          {/* Thumbnail Section */}
          <div className="thumbnail-container">
            {getThumbnails().map((image, index) => (
              <div
                key={index}
                className={`thumbnail-item ${index === 1 ? 'active' : ''}`}
                onClick={() =>
                  setCurrentIndex((currentIndex + index - 1 + images.length) % images.length)
                }
              >
                <img src={image.src} alt={image.name} className="thumbnail-image" />
              </div>
            ))}
          </div>

          {/* Dot Indicators */}
          <div className="dot-container">
            {getDots().map((_, index) => (
              <div
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Catalogue;
